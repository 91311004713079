import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import {
  Container,
  TextField,
  Button,
  Typography,
  Grid,
  Box,
  Paper,
} from "@mui/material";
import Yuva from "./Asset/yuva_logo.png";
import Nipan from "./Asset/Nipan.png";
import cbsc2 from "./Asset/cbsc2.png";
import Govlogo from "./Asset/Govlogo.png";
import iplogo from "./Asset/IPLOGO.jpeg";
import kendriya from "./Asset/kendriya.png";
import nvs from "./Asset/nvs_logo_color2.png";
import axios from 'axios';
import LoaderSplash from "../layouts/LoaderSplash";
import { Popup } from "../layouts/Popup";
import { Analytics } from "@vercel/analytics/react"

const MUIRegistrationForm = () => {
  const navigate = useNavigate(); // Initialize useNavigate
  const { schoolmail } = useParams();
  const [isLoading, setLoading] = useState(false);

  const validationSchema = Yup.object({
    affiliationNumber: Yup.string()
      .matches(/^[0-9]{5,7}$/, "Affiliation number must be 5-7 digits")
      .notRequired(),
    schoolName: Yup.string().required("School's name is required"),
    teacherCoordinatorName: Yup.string().required(
      "Name of Teacher Coordinator for the Event is required"
    ),
    teacherCoordinatorMobile: Yup.string()
      .matches(/^[0-9]{10}$/, "Mobile number must be 10 digits")
      .required("Mobile number of Teacher Coordinator is required"),
    teacherCoordinatorEmail: Yup.string()
      .email("Invalid email address")
      .required("Email ID of Teacher Coordinator is required"),
  });

  const formik = useFormik({
    initialValues: {
      affiliationNumber: "",
      schoolName: "",
      teacherCoordinatorName: "",
      teacherCoordinatorMobile: "",
      teacherCoordinatorEmail: "",
      schoolMail: schoolmail,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        // Live
        await axios.post(`${process.env.REACT_APP_API_URL}/participants/save-participants`, values);

        // Testing:
        // await axios.post(`${process.env.REACT_APP_LOCAL_API_URL}/participants/save-participants`, values);

        setLoading(false)
        Popup('success', 'Success', 'Participant registered successfully!', 4000);

        console.log("Form submitted:", values);

        // Navigate to the confirmation page with form values
        // Redirect after 4 seconds
        setTimeout(() => {
          navigate("/confirmation", { state: values });
        }, 4000);
      } catch (error) {
        setLoading(false);

        // Check for specific status code and set appropriate messages
        if (error.response?.status === 409) {
          Popup('error', 'Conflict', error.response?.data?.message || 'Participant with the provided email or mobile number is already registered.', 4000);
        } else {
          Popup('error', 'Something went wrong', error.response?.data?.message || 'Could not save details.', 4000);
        }

        console.log(error);

      }

    },
    onReset: () => {
      console.log("Form reset!");
    },
  });

  const renderLabel = (label, isRequired = true) => (
    <>
      {label} {isRequired && <span style={{ color: "red" }}>*</span>}
    </>
  );

  return (
    <>
      <Analytics />
      {isLoading && <LoaderSplash show={isLoading} />}
      <div
        style={{
          width: "100%",
          minHeight: "100vh",
          padding: "25px",
          backgroundColor: "rgb(226, 238, 224)",
        }}
      >
        <Container maxWidth="sm">
          <Paper
            elevation={3}
            sx={{
              padding: "20px",
              borderRadius: "10px",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              backgroundColor: "rgb(251, 247, 247)",
            }}
          >
            {/* Unified Background Color for Logo and Title */}
            <Box
              sx={{
                width: "100%",
                padding: "0px",
                display: "flex",
                justifyContent: "space-between",
                mb: 2,
              }}
            >
              {/* Image Container for Yuva */}
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "0 13px", // Adjust padding as needed
                }}
              >
                <img
                  src={iplogo}
                  alt="logo"
                  style={{ width: "70%", height: "auto" }}
                />
              </Box>

              {/* Image Container for Nipan */}
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "0 13px", // Adjust padding as needed
                }}
              >
                <img
                  src={Govlogo}
                  alt="logo"
                  style={{ width: "70%", height: "auto" }}
                />
              </Box>

              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "0 13px", // Adjust padding as needed
                }}
              >
                <img
                  src={Nipan}
                  alt="logo"
                  style={{ width: "70%", height: "auto" }}
                />
              </Box>
            </Box>


            <Typography variant="h5" gutterBottom textAlign="center">
              Registration Form
            </Typography>
            <form onSubmit={formik.handleSubmit} onReset={formik.handleReset} sx={{ mt: 4 }}>
              <Grid container spacing={2}>
                {/* Affiliation Number */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="affiliationNumber"
                    name="affiliationNumber"
                    label={renderLabel("Affiliation Number", false)} // Pass false to remove the asterisk
                    value={formik.values.affiliationNumber}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.affiliationNumber &&
                      Boolean(formik.errors.affiliationNumber)
                    }
                    helperText={
                      formik.touched.affiliationNumber &&
                      formik.errors.affiliationNumber
                    }
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderRadius: "8px",
                        },
                        "&:hover fieldset": {
                          borderColor: "grey !important",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "grey !important",
                        },
                      },
                    }}
                  />
                </Grid>

                {/* School's Name */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="schoolName"
                    name="schoolName"
                    label={renderLabel("School's Name")}
                    value={formik.values.schoolName}
                    onChange={formik.handleChange}
                    error={formik.touched.schoolName && Boolean(formik.errors.schoolName)}
                    helperText={formik.touched.schoolName && formik.errors.schoolName}
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderRadius: "8px",
                        },
                        "&:hover fieldset": {
                          borderColor: "grey !important",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "grey !important",
                        },
                      },
                    }}
                  />
                </Grid>

                {/* Teacher Coordinator's Name */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="teacherCoordinatorName"
                    name="teacherCoordinatorName"
                    label={renderLabel("Name of Teacher Coordinator for the Event")}
                    value={formik.values.teacherCoordinatorName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.teacherCoordinatorName &&
                      Boolean(formik.errors.teacherCoordinatorName)
                    }
                    helperText={
                      formik.touched.teacherCoordinatorName &&
                      formik.errors.teacherCoordinatorName
                    }
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderRadius: "8px",
                        },
                        "&:hover fieldset": {
                          borderColor: "grey !important",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "grey !important",
                        },
                      },
                    }}
                  />
                </Grid>

                {/* Teacher Coordinator's Mobile */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="teacherCoordinatorMobile"
                    name="teacherCoordinatorMobile"
                    label={renderLabel("Mobile Number of Teacher Coordinator")}
                    value={formik.values.teacherCoordinatorMobile}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.teacherCoordinatorMobile &&
                      Boolean(formik.errors.teacherCoordinatorMobile)
                    }
                    helperText={
                      formik.touched.teacherCoordinatorMobile &&
                      formik.errors.teacherCoordinatorMobile
                    }
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderRadius: "8px",
                        },
                        "&:hover fieldset": {
                          borderColor: "grey !important",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "grey !important",
                        },
                      },
                    }}
                  />
                </Grid>

                {/* Teacher Coordinator's Email */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="teacherCoordinatorEmail"
                    name="teacherCoordinatorEmail"
                    label={renderLabel("Email ID of Teacher Coordinator")}
                    value={formik.values.teacherCoordinatorEmail}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.teacherCoordinatorEmail &&
                      Boolean(formik.errors.teacherCoordinatorEmail)
                    }
                    helperText={
                      formik.touched.teacherCoordinatorEmail &&
                      formik.errors.teacherCoordinatorEmail
                    }
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderRadius: "8px",
                        },
                        "&:hover fieldset": {
                          borderColor: "grey !important",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "grey !important",
                        },
                      },
                    }}
                  />
                </Grid>

                {/* Submit and Reset Buttons */}
                <Grid item xs={12} sx={{ marginTop: "2%" }}>
                  <Grid container spacing={2} justifyContent="space-between">
                    <Grid item xs={4}>
                      <Button
                        color="secondary"
                        variant="contained"
                        fullWidth
                        type="reset"
                        sx={{ textTransform: "none", borderRadius: "8px" }}
                      >
                        Reset
                      </Button>
                    </Grid>
                    <Grid item xs={4}>
                      <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        type="submit"
                        sx={{ textTransform: "none", borderRadius: "8px" }}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>



              {/* Technical issue note */}
              <Box mt={2}>
                <Typography variant="body2" color="textSecondary" textAlign="center">
                  If you are facing any technical issue, feel free to contact at
                  +919210463129.
                </Typography>
              </Box>
            </form>
            <Box
              sx={{
                width: "100%",
                padding: "0px",
                display: "flex",
                justifyContent: "space-between",
                mb: 2,
                mt: "10px",
              }}
            >
              {/* Image Container for Yuva */}
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "0 13px", // Adjust padding as needed
                }}
              >
                <img
                  src={cbsc2}
                  alt="logo"
                  style={{ width: "60%", height: "auto" }}
                />
              </Box>

              {/* Image Container for Nipan */}
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "0 13px", // Adjust padding as needed
                }}
              >
                <img
                  src={kendriya}
                  alt="logo"
                  style={{ width: "70%", height: "auto" }}
                />
              </Box>
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "0 13px", // Adjust padding as needed
                }}
              >
                <img
                  src={nvs}
                  alt="logo"
                  style={{ width: "60%", height: "auto" }}
                />
              </Box>
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "0 13px", // Adjust padding as needed
                }}
              >
                <img
                  src={Yuva}
                  alt="logo"
                  style={{ width: "70%", height: "auto" }}
                />
              </Box>
            </Box>

          </Paper>
        </Container>
      </div>
    </>
  )
};

export default MUIRegistrationForm;
