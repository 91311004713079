import "./App.css";
import MUIRegistrationForm from "./Component/RegistrationForm";
import ConfirmationPage from "./Component/Congratulation";
// import Heading from './Component/Heading';
// import ImgContainer from './Component/ImgContainer';
// import Match from './Component/Match';
// import AfterHeading from './Component/AfterHeading';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/:schoolmail" element={<MUIRegistrationForm />} />
          <Route path="/confirmation" element={<ConfirmationPage />} />
        </Routes>
      </Router>
      {/*
<div style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}>
  <Heading />
  <AfterHeading />
  <ImgContainer />
  <Match />
  <Match />
  <Match />
  <Match />
  <Match />
</div>
*/}
    </div>
  );
}

export default App;
